import { State } from './types'

export const user = (state: State) => {
  const { settings } = state
  const { user } = settings
  return user
}

export const room = (state: State) => {
  const { settings, music } = state
  const { user } = settings
  const { room, upVotes, paidTracks, tracks, buffers } = music
  return { room, user, upVotes, paidTracks, tracks, buffers }
}
