import { computePrice, lydia } from '@/services/payment'
import {
  Stack,
  Button,
  Box,
  Flex,
  DarkMode,
  Text,
  Input,
  InputLeftAddon,
  InputGroup,
  Spinner,
} from '@chakra-ui/react'
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Stripe } from '@stripe/stripe-js'
import React, { useState, useEffect, useRef } from 'react'
import * as analytics from '@/services/analytics'
import { useTranslation } from 'react-i18next'
import strings from '@/strings'

type Props = {
  onPaymentDone: (error?: string) => void
  totalTokens: number
  userId?: string
  roomId?: number
}

type Phone = {
  number: string
  onChange: (value: any) => void
  valid: boolean
  local: string
}
const usePhoneNumber = (): Phone => {
  const LOCAL_STORAGE_KEY = 'jambox-payment-phone-number'
  const init = () => localStorage.getItem(LOCAL_STORAGE_KEY) || ''
  const saveLS = (v: any) => localStorage.setItem(LOCAL_STORAGE_KEY, v)
  const [number, set] = useState(init)
  const compact = number.trim().replace(/ /g, '').replace(/^0+/, '')
  const valid = compact.length === 9
  useEffect(() => (valid ? saveLS(number) : undefined), [valid, number])
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, ' '].map(t => t.toString())
    const value = event.target.value
    const allNumbers = (acc: boolean, v: string) => acc && numbers.includes(v)
    const areNumbers = value.split('').reduce(allNumbers, true)
    if (areNumbers) set(value)
  }
  const local = '+33' + compact
  return { number, valid, local, onChange }
}

const Separator = ({ content }: { content: string }) => (
  <Flex justifyContent="center" alignItems="center">
    <hr style={{ flex: 1 }} />
    <Box p={3}>
      <Text fontWeight="bold" textTransform="uppercase">
        {content}
      </Text>
    </Box>
    <hr style={{ flex: 1 }} />
  </Flex>
)

type LydiaFormProps = { onSubmit: (event: any) => void; phone: Phone }
const LydiaForm = ({ onSubmit, phone }: LydiaFormProps) => {
  const { t } = useTranslation()
  return (
    <Stack as="form" onSubmit={onSubmit}>
      <InputGroup>
        <InputLeftAddon>+33</InputLeftAddon>
        <Input
          type="tel"
          placeholder="6 06 06 06 06"
          value={phone.number}
          onChange={phone.onChange}
        />
      </InputGroup>
      <Button
        colorScheme="lydiaBlue"
        color="white"
        type="submit"
        disabled={!phone.valid}
      >
        {t(strings.pay.payWithLydia)}
      </Button>
    </Stack>
  )
}

const LydiaLoading = ({ onClick }: { onClick: (event: any) => void }) => {
  const { t } = useTranslation()
  const [timeouted, setTimeouted] = useState(false)
  useEffect(() => {
    const id = setTimeout(() => setTimeouted(true), 15000)
    return () => clearTimeout(id)
  }, [])
  return (
    <Box>
      <Text>{t(strings.pay.lydiaPaymentRequest)}</Text>
      <Flex justifyContent="center" paddingTop={6}>
        {!timeouted && <Spinner />}
        {timeouted && (
          <Button colorScheme="lydiaBlue" color="white" onClick={onClick}>
            {t(strings.pay.lydiaNotWorking)}
          </Button>
        )}
      </Flex>
    </Box>
  )
}

type StripeFormProps = {
  onSubmit: (event: any) => void
  stripe: any
  loading: boolean
  totalTokens: number
  disabled: boolean
}
const StripeForm = (props: StripeFormProps) => {
  const border = 'rgba(255, 255, 255, 0.16)'
  const { t } = useTranslation()
  return (
    <Stack as="form" onSubmit={props.onSubmit}>
      <Box py={3} px={3} borderColor={border} borderWidth="1px" rounded="lg">
        <CardElement
          options={{
            disabled: props.disabled,
            style: {
              base: {
                fontSize: '16px',
                backgroundColor: 'transparent',
                padding: '2rem',
                color: 'white',
                '::placeholder': { color: '#adadad' },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      </Box>
      <Flex justifyContent="flex-end">
        <DarkMode>
          <Button
            type="submit"
            isDisabled={props.disabled || !props.stripe}
            isLoading={props.loading}
            colorScheme="orange"
          >
            {t(strings.pay.pay)} {computePrice(props.totalTokens)} €
          </Button>
        </DarkMode>
      </Flex>
    </Stack>
  )
}

export const TokensPaymentForm = (props: Props) => {
  const { onPaymentDone, totalTokens, userId, roomId } = props
  const stripe = useStripe()
  const elements = useElements()
  const phone = usePhoneNumber()
  const [paymentLoading, setPaymentLoading] = useState(false)
  const [lydiaInProgress, setLydiaInProgress] = useState(false)
  const startTime = useRef(Date.now())
  const { t } = useTranslation()
  useEffect(() => {
    analytics.beginCheckout()
  }, [])
  const handleSubmit = async (event: any) => {
    event.preventDefault()
    try {
      if (!stripe || !elements || !userId || !roomId)
        throw new Error('userId or roomId or stripe or elements not found.')
      setPaymentLoading(true)
      const elapsed_time = Date.now() - startTime.current
      analytics.add({
        action: 'pay_tokens',
        category: 'ecommerce',
        label: 'stripe',
        value: elapsed_time,
      })
      const card = elements.getElement(CardElement)
      if (!card) throw new Error('card not found')
      const res = await stripe.createToken(card)
      const { token } = res
      if (!token) throw new Error('token not found')
      const amount = totalTokens
      await pay({ stripe, token: token.id, amount, userId, roomId })
      onPaymentDone()
    } catch (err) {
      console.error(err)
      setPaymentLoading(false)
      onPaymentDone((err as Error).toString() ?? 'server_error')
    }
  }

  const lydiaSubmit = async (event: any) => {
    event.preventDefault()
    if (!phone.valid) return
    if (!userId) throw new Error('userId not found')
    setLydiaInProgress(true)
    const elapsed_time = Date.now() - startTime.current
    analytics.add({
      action: 'pay_tokens',
      category: 'ecommerce',
      label: 'lydia',
      value: elapsed_time,
    })
    const amount = totalTokens
    const message = t(strings.pay.payWithLydiaMessage, { amount })
    const params = { message, amount, recipient: phone.local, uid: userId }
    const result = await lydia(params)
    if (result.error !== '0') setLydiaInProgress(false)
  }

  return (
    <>
      <Separator content={t(strings.pay.payWithCreditCard)} />
      <StripeForm
        disabled={lydiaInProgress}
        onSubmit={handleSubmit}
        stripe={stripe}
        loading={paymentLoading}
        totalTokens={totalTokens}
      />
      <Separator content={t(strings.common.or).toUpperCase()} />
      {!lydiaInProgress && <LydiaForm onSubmit={lydiaSubmit} phone={phone} />}
      {lydiaInProgress && (
        <LydiaLoading onClick={() => setLydiaInProgress(false)} />
      )}
    </>
  )
}

type Pay = {
  stripe: Stripe
  token: string
  amount: number
  userId: string
  roomId: number
}
const pay = async ({ stripe, token, amount, userId, roomId }: Pay) =>
  new Promise<void>(async (resolve, reject) => {
    if (!stripe) return
    const response = await fetch(`/api/payment/create-intent`, {
      method: 'POST',
      body: JSON.stringify({ amount, userId, roomId }),
    }).catch(err => {
      console.error('Error while calling `createPaymentIntent`', err)
      reject(err)
    })
    if (!response) return
    const { clientSecret } = await response.json()
    const result = await stripe.confirmCardPayment(clientSecret, {
      payment_method: { card: { token } },
    })
    if (result.error) {
      console.error(
        'Error while calling `confirmCardPayment`',
        result.error.message
      )
      reject(result.error.code)
      return
    }
    if (result.paymentIntent.status === 'succeeded') {
      resolve()
    }
  })
