import { useEffect } from 'react'
import { useDispatch, useSelector } from '@/store/hooks'
import * as selectors from '@/store/selectors'
import * as actions from '@/store/actions'
import * as services from '@/services'
import { User } from '@/types/user'

export const useUser = () => {
  const dispatch = useDispatch()
  const current = useSelector(selectors.user)
  const update = (u: User | null) => {
    const action = actions.settings.updateUser(u)
    dispatch(action)
  }
  return { current, update }
}

export const useCurrentTokens = () => {
  const user = useUser()
  const updateCurrentTokens = async () => {
    const { current } = user
    if (current) {
      const { id } = current
      const totalTokens = await services.user.getTotalTokens(id)
      if (totalTokens !== current.totalTokens)
        user.update({ ...current, totalTokens })
    }
  }
  useEffect(() => {
    if (user.current) updateCurrentTokens()
    if (!user.current) services.user.createUser().then(user.update)
  }, [user.current])
  return user.current
}
