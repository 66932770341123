import {
  useCallback,
  useState,
  useEffect,
  forwardRef,
  ForwardedRef,
} from 'react'
import { Button, Flex, Box, Container } from '@chakra-ui/react'
import * as DixHeures from '@/libs/dix-heures'
import { Track } from '@/types/room'
import { Songs } from '@/store/types'

const toTrack = (track: Track): DixHeures.Track[] => {
  const { title, id, serviceId } = track
  return [{ title, id, serviceId }]
}

const createDixHeures = (
  tracks_: Track[],
  sort: DixHeures.CompareFn,
  context: HTMLAudioElement
) => {
  return () => {
    const tracks = tracks_.flatMap(toTrack)
    return new DixHeures.DixHeures({ tracks, sort, context })
  }
}

type UseDixHeures = Props & { ref: ForwardedRef<any> }
const useDixHeures = (props: UseDixHeures) => {
  const [canNext, setCanNext] = useState(true)
  const [player, setPlayer] = useState<DixHeures.DixHeures | null>(null)
  const init = () => player?.tracks ?? []
  const [playing, setPlaying] = useState(false)
  const [tracks, setTracks] = useState<DixHeures.Track[]>(init)
  useEffect(() => {
    player?.replace(props.tracks.flatMap(toTrack)).then(() => {
      props.setPlaylist?.(player?.tracks ?? [])
    })
  }, [props.tracks])
  useEffect(() => {
    if (props.ref instanceof Function) props.ref(player)
    else if (props.ref) props.ref.current = player
  }, [props.ref])
  useEffect(() => {
    props.setPlaylist?.(player?.tracks ?? [])
    const onPlaying = (id: DixHeures.ID) => {
      setPlaying(true)
      props.onPlaying(id)
      const track = props.tracks.find(t => t.id === id)
      if (track) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: track.title,
          artist: track.artist,
          artwork: track.thumbnailUrl ? [{ src: track.thumbnailUrl }] : [],
        })
      }
    }
    const onPaused = (_id: DixHeures.ID) => setPlaying(false)
    const onRemoved = props.onRemoved
    const onSorted = (tracks: DixHeures.Track[]) => {
      setTracks(tracks)
      props.setPlaylist?.(tracks)
    }
    player?.addListener('playing', onPlaying)
    player?.addListener('paused', onPaused)
    player?.addListener('sorted', onSorted)
    player?.addListener('removed', onRemoved)
    onSorted(player?.tracks ?? [])
    return () => {
      player?.removeListener('playing', onPlaying)
      player?.removeListener('paused', onPaused)
      player?.removeListener('sorted', onSorted)
      player?.removeListener('removed', onRemoved)
      player?.close()
    }
  }, [player])
  const playPause = async () => {
    return playing ? player?.pause() : await player?.resume()
  }
  const next = async () => {
    try {
      if (canNext) {
        setCanNext(false)
        await player?.next()
      }
    } catch (error) {
    } finally {
      setCanNext(true)
    }
  }
  return {
    playing,
    tracks,
    player,
    playPause,
    next,
    canNext,
    audioRef: useCallback((ref: HTMLAudioElement) => {
      setPlayer(createDixHeures(props.tracks, props.sort, ref))
    }, []),
  }
}

export type Props = {
  tracks: Track[]
  buffers: Songs
  setPlaylist?: (tracks: DixHeures.Track[]) => void
  sort: DixHeures.CompareFn
  render: any
  onPlaying: (id: DixHeures.ID) => void | Promise<void>
  onRemoved: (id: DixHeures.ID) => void
}
export const Player = forwardRef<DixHeures.DixHeures, Props>((props, ref) => {
  const dixHeures = useDixHeures({ ...props, ref })
  useEffect(() => {
    dixHeures.player?.updateSortTracks(props.sort)
  }, [props.sort, dixHeures.player])
  return (
    <Flex
      position="fixed"
      top="calc(100vh - 200px)"
      zIndex={100000}
      width="100%"
      display="flex"
      justifyContent="center"
    >
      <Container
        padding="4"
        width="100%"
        maxWidth={500}
        background="var(--chakra-colors-purple-800)"
        borderRadius={20}
      >
        <audio key="player" ref={dixHeures.audioRef} />
        <Flex>{props.render}</Flex>
        <Flex justifyContent="space-between">
          <Box flex="1" />
          <Flex flex="1" justifyContent="center">
            <Button onClick={dixHeures.playPause}>
              {dixHeures.playing ? 'Pause' : 'Play'}
            </Button>
          </Flex>
          <Flex flex="1" justifyContent="flex-end">
            <Button
              disabled={!dixHeures.canNext || props.tracks.length <= 1}
              onClick={dixHeures.next}
            >
              Next
            </Button>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  )
})
