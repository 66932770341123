import { Icon, IconProps } from '@chakra-ui/react'

export const MusicIcon = (props: IconProps): JSX.Element => (
  <Icon
    viewBox="0 0 512 512"
    xmlns="http://www.w3.org/2000/svg"
    fill="white"
    {...props}
  >
    <title>Musics</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="music-note"
        transform="translate(0.000000, 0.002586)"
        fill="#ffffff"
        fillRule="nonzero"
      >
        <path
          d="M209.297,106.467414 C182.256,86.6484145 154.512,62.2214145 134.766,32.6024145 L117.48,6.67441446 C113.818,1.18141446 106.992,-1.26458554 100.649,0.639414461 C94.321,2.55841446 90,8.38841446 90,14.9954145 L90,250.339414 C81.14,245.172414 70.977,241.995414 60,241.995414 C26.909,241.995414 0,268.904414 0,301.995414 C0,335.086414 26.909,361.994414 60,361.994414 C93.091,361.994414 120,335.085414 120,301.994414 L120,64.2464145 C141.196,91.0384145 167.71,113.176414 191.558,130.666414 C203.276,139.250414 210,152.493414 210,166.994414 C210,191.808414 189.814,211.994414 165,211.994414 C156.709,211.994414 150,218.703414 150,226.994414 C150,235.285414 156.709,241.994414 165,241.994414 C206.353,241.994414 240,208.347414 240,166.994414 C240,143.190414 228.53,120.573414 209.297,106.467414 Z"
          id="Path"
        ></path>
        <path
          d="M492.884,122.566414 L280.884,182.566414 C274.439,184.412414 270,190.300414 270,196.995414 L270,400.339414 C261.14,395.172414 250.977,391.995414 240,391.995414 C206.909,391.995414 180,418.904414 180,451.995414 C180,485.086414 206.909,511.995414 240,511.995414 C273.091,511.995414 300,485.086414 300,451.995414 C300,448.575414 300,268.315414 300,268.315414 L482,216.886414 L482,340.339414 C473.14,335.172414 462.977,331.995414 452,331.995414 C418.909,331.995414 392,358.904414 392,391.995414 C392,425.086414 418.909,451.995414 452,451.995414 C485.091,451.995414 512,425.086414 512,391.995414 C512,388.575414 512,136.995414 512,136.995414 C512,127.074414 502.51,119.847414 492.884,122.566414 Z"
          id="Path"
        ></path>
      </g>
    </g>
  </Icon>
)
