const gtag = (...args: any[]) => {
  try {
    const win: any = window
    const valid = win.gtag && win.gtag instanceof Function
    if (valid) win.gtag(...args)
  } catch (error) {}
}

export type Add = {
  action: string
  category: string
  label?: string
  value?: number
}
export const add = (params: Add) => {
  const { action, category, label, ...rest } = params
  gtag('event', action, {
    event_category: category,
    event_label: label,
    ...rest,
  })
}

export const beginCheckout = () => gtag('event', 'begin_checkout')
export const search = (value: string) => gtag('event', 'search', { value })
