import { isRoomOwner } from '@/services/user'
import { PaidTrack, Track, Room, UpVote } from '@/types/room'
import { User } from '@/types/user'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import { Flex, Stack } from '@chakra-ui/layout'
import { Button } from '@chakra-ui/react'
import React, { useState } from 'react'
import { NextChakraLink } from '../NextChakraLink'
import { SearchInput } from './SearchInput'
import { SearchResults } from './SearchResults'
import { useTranslation } from 'react-i18next'
import strings from '@/strings'

type SearchProps = {
  user: User | null
  room: Room | null
  upVotes: UpVote[]
  paidTracks: PaidTrack[]
  tracks: Track[]
}

const Search = ({ room, user, upVotes, paidTracks, tracks }: SearchProps) => {
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState('')
  const [typeValue, setTypeValue] = useState<'music' | 'playlist'>('music')

  return (
    <Stack px={4} flex="1" maxW="500px" spacing="4" w="full" pb={20}>
      <Flex>
        <Button
          as={NextChakraLink}
          aria-label="Back"
          href={`/${room?.publicId}`}
          leftIcon={<ChevronLeftIcon boxSize="20px" />}
        >
          {t(strings.search.backToPlaylist)}
        </Button>
      </Flex>

      <SearchInput
        onSubmit={(searchValue, typeValue) => {
          setSearchValue(searchValue)
          setTypeValue(typeValue)
        }}
        isOwner={isRoomOwner(user?.id, room?.ownerId)}
      />
      <SearchResults
        tracks={tracks}
        room={room}
        searchValue={searchValue}
        type={typeValue}
        upVotes={upVotes}
      />
    </Stack>
  )
}

export default Search
