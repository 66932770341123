import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
  Text,
  Stack,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { MinusIcon, SmallAddIcon } from '@chakra-ui/icons'
import { useDispatch, useSelector } from '@/store/hooks'
import * as selectors from '@/store/selectors'
import * as actions from '@/store/actions'
import { TokensPaymentForm } from '../TokensPaymentForm'
import { subscribeToTokens } from '@/services/payment'
import * as analytics from '@/services/analytics'
import strings from '@/strings'

type PayTokensModalProps = {
  isOpen: boolean
  roomId?: number
  onClose: (validated: boolean, totalTokens?: number) => void
}

if (!process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY)
  throw Error('NEXT_PUBLIC_STRIPE_PUBLIC_KEY is missing')
const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY)

export const PayTokensModal = (props: PayTokensModalProps) => {
  const { isOpen, onClose, roomId } = props
  const { t } = useTranslation()
  const [totalTokens, setTotalTokens] = useState(1)
  const user = useSelector(selectors.user)
  const toast = useToast({ status: 'error', variant: 'solid' })
  const dispatch = useDispatch()
  useEffect(() => {
    if (!isOpen || !user) return
    const sub = subscribeToTokens(user.id, (totalTokens = 0) => {
      let received = false
      if (totalTokens > 0 && !received) {
        analytics.add({
          action: 'tokens_added',
          category: 'ecommerce',
          value: totalTokens,
        })
        received = true
        dispatch(actions.settings.updateUser(user))
        toast({
          title: `${t(strings.common.yes).toUpperCase()} !`,
          description: t(strings.pay.receivedTokens, { totalTokens }),
          status: 'success',
        })
        onClose(true, totalTokens)
      }
    })
    return () => {
      sub.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const handlePaymentDone = (error?: string) => {
    if (error)
      toast({
        title: t(strings.pay.aProblemHappened),
        description: error,
      })
  }

  const onAddClick = (e: any) => {
    e.preventDefault()
    setTotalTokens(totalTokens + 1)
  }
  const onRemoveClick = (e: any) => {
    e.preventDefault()
    setTotalTokens(totalTokens - 1)
  }

  return (
    <Modal onClose={() => onClose(false)} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent bgColor="purple.900">
        <ModalHeader>{t(strings.pay.buyMusics)}</ModalHeader>
        <ModalCloseButton />
        <ModalBody as={Stack} spacing={6}>
          <HStack mx="auto" spacing={10}>
            <IconButton
              icon={<MinusIcon />}
              aria-label="Less tokens"
              onClick={onRemoveClick}
              isDisabled={totalTokens === 1}
            />
            <Text fontSize="40px">{totalTokens}</Text>

            <IconButton
              icon={<SmallAddIcon boxSize="20px" />}
              aria-label="More tokens"
              onClick={onAddClick}
            />
          </HStack>

          <Elements stripe={stripePromise}>
            <TokensPaymentForm
              onPaymentDone={handlePaymentDone}
              totalTokens={totalTokens}
              userId={user?.id}
              roomId={roomId}
            />
          </Elements>
        </ModalBody>
        <ModalFooter />
      </ModalContent>
    </Modal>
  )
}
