import React, { useEffect, useState, useRef } from 'react'
import {
  SimpleGrid,
  Stack,
  useDisclosure,
  useToast,
  VStack,
  Text,
  Button,
  Flex,
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  Box,
} from '@chakra-ui/react'
import { Loading } from '../Loading'
import { MusicItem } from './MusicItem'
import { Music, PlaylistPreview, Room, UpVote, Track } from '@/types/room'
import { PayTokensModal } from '../modals/PayTokensModal'
import {
  getPlaylist,
  insertToken,
  searchMusics,
  searchPlaylists,
} from '@/services/search'
import { useDispatch, useSelector } from '@/store/hooks'
import * as actions from '@/store/actions'
import * as selectors from '@/store/selectors'
import Image from 'next/image'
import { useTranslation } from 'react-i18next'
import { isRoomOwner } from '@/services/user'
import { ChevronLeftIcon } from '@chakra-ui/icons'
import * as roomService from '@/services/room'
import { MusicIcon } from '@/assets/icons/MusicIcon'
import * as analytics from '@/services/analytics'
import strings from '@/strings'

export type SearchResultsProps = {
  room: Room | null
  tracks: Track[]
  upVotes: UpVote[]
  searchValue: string
  type: 'music' | 'playlist'
}
export const SearchResults = (props: SearchResultsProps) => {
  const { room, searchValue, type, tracks } = props
  const musicToAdd = useRef<Music | null>(null)
  const user = useSelector(selectors.user)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [isLoading, setIsLoading] = useState(false)
  const [musicResults, setMusicResults] = useState<Music[]>([])
  const [playlistResults, setPlaylistResults] = useState<PlaylistPreview[]>([])
  const [playlistId, setPlaylistId] = useState<string | undefined>()
  const [playlistMusicResults, setPlaylistMusicResults] = useState<Music[]>([])
  const [musicLoadingId, setMusicLoadingId] = useState<string | undefined>()
  const [paidTrackIds, setPaidTrackIds] = useState<string[]>([])
  const [isAddWholePlaylistLoading, setIsAddWholePlaylistLoading] =
    useState(false)

  const toast = useToast({ status: 'error', variant: 'solid' })
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (!searchValue || !room) return
    setIsLoading(true)
    const run = async () => {
      const { ownerId } = room
      if (type === 'music') {
        const media = room.isSpotify ? 'spotify' : 'youtube-music'
        const searchResults = await searchMusics(searchValue, ownerId, media)
        setMusicResults(searchResults)
        setIsLoading(false)
      } else if (!playlistId) {
        const searchResults = await searchPlaylists(searchValue, ownerId)
        setPlaylistResults(searchResults)
        setIsLoading(false)
      } else {
        const musics = await getPlaylist(playlistId)
        setPlaylistMusicResults(musics)
        setIsLoading(false)
      }
    }
    run()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, type, playlistId])

  const handleMusicAddClick = async (music: Music, totalTokens?: number) => {
    if (!room || !user) return
    if (isRoomOwner(user?.id, room.ownerId)) {
      await dispatch(actions.music.adminAddTrack(music))
      musicToAdd.current = null
    } else if (user?.id && room?.id && (totalTokens ?? 0 > 0)) {
      setMusicLoadingId(music.id)
      analytics.add({ action: 'add_music_with_token', category: 'ecommerce' })
      const data = await insertToken({ room, music, userId: user.id })
      if (data.message === 'success') {
        toast({ title: t(strings.search.musicAdded), status: 'success' })
        const newUser = { ...user, totalTokens: totalTokens! - 1 }
        dispatch(actions.settings.updateUser(newUser))
        setPaidTrackIds(p => [...p, music.id])
      } else {
        toast({
          title: t(strings.search.anErrorHappened),
          description: data.message,
        })
      }
      setMusicLoadingId(undefined)
      musicToAdd.current = null
    } else if (!musicToAdd.current) {
      analytics.add({ action: 'add_music_no_token', category: 'ecommerce' })
      musicToAdd.current = music
      onOpen()
    }
  }

  const handleAddTheWholePlaylistClick = async () => {
    if (!room) return
    const spotifyMusics = playlistMusicResults.filter(
      music => !tracks.find(p => p.serviceId === music.id)
    )
    setIsAddWholePlaylistLoading(true)
    analytics.add({ action: 'add_whole_playlist', category: 'engagement' })
    const { ownerId } = room
    if (room.isSpotify)
      roomService.tracks.addPlaylist({ musics: spotifyMusics, room, ownerId })
    else {
      const previousTracks = new Set<string>()
      const total = spotifyMusics.length
      let i = 0
      for (const mus of spotifyMusics) {
        const title = `${mus.artist} - ${mus.title}`
        const [music] = await searchMusics(title, room.ownerId, 'youtube-music')
        if (music && !previousTracks.has(music.id)) {
          previousTracks.add(music.id)
          dispatch(actions.music.adminAddTrack(music))
        }
        i += 1
        if (i % 5 === 0 && !toast.isActive('progress')) {
          const description = `${t(strings.search.adding)} ${i}/${total}`
          toast({ id: 'progress', duration: 1000, description, status: 'info' })
        }
      }
    }
    setIsAddWholePlaylistLoading(false)
  }

  const removeDuplicatesId = (v: { id: any }, i: any, a: any[]) =>
    a.findIndex(t => t.id === v.id) === i

  if (isLoading) return <Loading style={{ marginTop: '1rem' }} />
  const onPayClose = (value: boolean, totalTokens?: number) => {
    onClose()
    if (value && musicToAdd.current)
      handleMusicAddClick(musicToAdd.current, totalTokens)
    musicToAdd.current = null
  }
  return (
    <Stack spacing="4">
      <PayTokensModal isOpen={isOpen} onClose={onPayClose} roomId={room?.id} />
      {musicResults.length === 0 &&
        playlistResults.length === 0 &&
        playlistMusicResults && (
          <Alert status="info" variant="left-accent">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>{t(strings.search.searchMusic)}</AlertTitle>
              <AlertDescription>
                {t(strings.search.searchMusicSubtitle)}
                {'\n'}
                {t(strings.search.easyAsABC)} <MusicIcon />
              </AlertDescription>
            </Box>
          </Alert>
        )}
      {type === 'music' &&
        musicResults
          .filter(music => !tracks.find(p => p.serviceId === music.id))
          .filter(music => !paidTrackIds.find(p => p === music.id))
          .map(music => (
            <MusicItem
              key={music.id}
              {...music}
              isLoading={music.id === musicLoadingId}
              onClick={() => handleMusicAddClick(music, user?.totalTokens)}
            />
          ))}
      {type === 'playlist' && !playlistId && (
        <SimpleGrid columns={{ base: 2, sm: 3 }} spacing={4}>
          {playlistResults.map(playlist => (
            <VStack
              cursor="pointer"
              onClick={() => setPlaylistId(playlist.id)}
              key={playlist.id}
            >
              <Image
                width="180px"
                height="180px"
                src={playlist.thumbnailUrl!}
                alt="playlist cover"
                className="rounded-md cover"
              />
              <Text textAlign="center" noOfLines={1}>
                {playlist.title}
              </Text>
            </VStack>
          ))}
        </SimpleGrid>
      )}
      {type === 'playlist' && playlistMusicResults.length > 0 && (
        <Stack flexDir="column" spacing={4}>
          <Flex>
            <Button
              onClick={() => setPlaylistId(undefined)}
              leftIcon={<ChevronLeftIcon />}
              size="sm"
            >
              {t(strings.common.back)}
            </Button>
          </Flex>

          {isRoomOwner(user!.id, room!.ownerId) && (
            <Button
              colorScheme="orange"
              onClick={handleAddTheWholePlaylistClick}
              isLoading={isAddWholePlaylistLoading}
            >
              {t(strings.search.addWholePlaylist)}
            </Button>
          )}
          {playlistMusicResults
            .filter(music => !tracks.find(p => p.serviceId === music.id))
            .map(music => (
              <MusicItem
                key={music.id}
                {...music}
                isLoading={music.id === musicLoadingId}
                onClick={() => handleMusicAddClick(music, user?.totalTokens)}
              />
            ))}
        </Stack>
      )}
    </Stack>
  )
}
