import { Music } from '@/types/room'
import { AddIcon } from '@chakra-ui/icons'
import {
  Flex,
  HStack,
  Stack,
  Text,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import Image from 'next/image'
import { PayTokensModal } from '../modals/PayTokensModal'

export const MusicItem = ({
  onClick,
  isLoading,
  ...music
}: Music & { onClick: () => void; isLoading: boolean }) => {
  return (
    <Flex
      justifyContent="space-between"
      align="center"
      w="full"
      data-testid="music-item"
    >
      <HStack spacing="4" data-testid="music-item" overflow="hidden">
        <Flex boxSize="80px" style={{ flexShrink: 0 }} pos="relative">
          <Image
            src={music.thumbnailUrl}
            layout="fill"
            alt={`${music.title} cover`}
            className="rounded-md"
          />
        </Flex>

        <Stack minW="0">
          <Text
            fontWeight="bold"
            textAlign="left"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {music.title}
          </Text>
          <Text
            textAlign="left"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {music.artist}
          </Text>
        </Stack>
      </HStack>

      <IconButton
        isLoading={isLoading}
        aria-label="Add music"
        onClick={onClick}
        size="sm"
        icon={<AddIcon />}
        colorScheme="orange"
      />
    </Flex>
  )
}
