import React, { useCallback, useEffect, useRef, useState } from 'react'
import dynamic from 'next/dynamic'
import {
  Box,
  Stack,
  Button,
  Heading,
  Flex,
  HStack,
  Text,
  Image,
} from '@chakra-ui/react'
import { pingSpotify } from '@/services/spotify'
import { NextRouter, useRouter } from 'next/router'
import { PaidTrack, Room, UpVote, Track } from '@/types/room'
import { useDispatch, useSelector } from '@/store/hooks'
import * as actions from '@/store/actions'
import * as selectors from '@/store/selectors'
import * as services from '@/services/room'
import { Playlist } from '@/components/Playlist'
import { Player } from '@/components/DixHeures'
import { Search } from '@/components/Search'
import * as DixHeures from '@/libs/dix-heures'
import { isRoomOwner } from '@/services/user'
import { useCurrentTokens } from '@/hooks/user'
import { NextChakraLink } from '@/components/NextChakraLink'
import { useTranslation } from 'react-i18next'
import * as analytics from '@/services/analytics'
import strings from '@/strings'

const useSubscriptions = (router: NextRouter) => {
  const dispatch = useDispatch()
  const [isPlaylistOnline, setIsPlaylistOnline] = useState(true)

  const startSpotifyPinger = (room: Room) => {
    pingSpotify(room.id, room.ownerId, setIsPlaylistOnline)
    const pinger = () => pingSpotify(room.id, room.ownerId, setIsPlaylistOnline)
    return setInterval(pinger, 10000)
  }

  const sRoom = (r: Room) => dispatch(actions.music.updateRoom(r))
  const sPaid = (p: PaidTrack[]) => dispatch(actions.music.updatePaidTracks(p))
  const sUpvotes = (u: UpVote[]) => dispatch(actions.music.updateUpVotes(u))
  const sTracks = (t: Track[]) => dispatch(actions.music.updateTracks(t))
  const addUp = (u: UpVote) => dispatch(actions.music.addUpVote(u))
  const delUp = (u: UpVote) => dispatch(actions.music.delUpVote(u))

  type Init = { room: Room; paidTracks: PaidTrack[]; upVotes: UpVote[] }
  const initializeRoom = async (params: Init) => {
    sRoom(params.room)
    sPaid(params.paidTracks)
    sUpvotes(params.upVotes)
    const rid = params.room.id
    const room = services.subscribeToRoomUpdates(rid, sRoom)
    const upVotes = await services.upvotes.subscribeToUpVotes(rid, addUp, delUp)
    const paidTracks = await services.paidTracks.subscribe(rid, sPaid)
    const tracks = await services.tracks.subscribe(rid, sTracks)
    return { room, paidTracks, upVotes, tracks }
  }

  useEffect(() => {
    if (!router.isReady) return
    if (!router.query.id) router.replace('/')
    if (!router.query.id) return
    const roomId = router.query.id?.toString()
    let unsubscribe = () => {}
    const run = async () => {
      const state = await services.getBy({ slug: roomId })
      if (!state) return
      const pinger = state.room.isSpotify && startSpotifyPinger(state.room)
      const allSubs = await initializeRoom(state)
      const subs = { ...allSubs, pinger }
      unsubscribe = () => {
        subs.room?.unsubscribe()
        subs.paidTracks?.unsubscribe()
        subs.upVotes?.unsubscribe()
        subs.tracks?.unsubscribe()
        if (subs.pinger) clearInterval(subs.pinger)
      }
    }
    run()
    return () => {
      unsubscribe()
    }
  }, [router])
  return isPlaylistOnline
}

type AddMusicButtonProps = { room: Room | null; online: boolean }
const AddMusicButton = (props: AddMusicButtonProps) => {
  const { room, online } = props
  const { t } = useTranslation()
  const [background, setBackground] = useState('transparent')
  useEffect(() => {
    const fun = () => {
      const { scrollY } = window
      setBackground(scrollY <= 50 ? 'transparent' : '#1d0c27')
    }
    window.addEventListener('scroll', fun)
    return () => window.removeEventListener('scroll', fun)
  }, [])
  const onClick = () =>
    analytics.add({
      action: 'will_search',
      category: 'engagement',
    })
  return (
    <Stack
      transition="all .1s"
      alignItems="center"
      py="4"
      position="sticky"
      top="0"
      background={background}
      zIndex="1000"
    >
      <Stack w="full" maxW="500px" spacing="4">
        <Heading>{room?.name}'s Playlist</Heading>
        {online && (
          <Button
            as={NextChakraLink}
            href={`${room?.publicId}?search=true`}
            colorScheme="orange"
            size="lg"
            onClick={onClick}
          >
            {t(strings.room.addMusic)}
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

export const RoomPage = () => {
  const data = useSelector(selectors.room)
  const { user, room, upVotes, paidTracks, tracks, buffers } = data
  const router = useRouter()
  const isPlaylistOnline = useSubscriptions(router)
  const isSearching = router.query.search === 'true'

  useCurrentTokens()
  const player = useRef<DixHeures.DixHeures>(null)

  const isPlaylistFull = tracks.length > 0
  const isOwner = isRoomOwner(user?.id, room?.ownerId)
  const isOwnerWithPlaylist = isPlaylistFull && isOwner
  const [playlist, setPlaylist] = useState<DixHeures.Track[]>([])
  const playingTrack = playlist[0] && tracks.find(t => t.id === playlist[0].id)
  const compareFn = useCallback(
    (t1: DixHeures.Track, t2: DixHeures.Track) => {
      const t1Paid = paidTracks.find(t => t.trackId === t1.id)
      const t2Paid = paidTracks.find(t => t.trackId === t2.id)
      const t1Likes = upVotes.filter(u => u.trackId === t1.id).length
      const t2Likes = upVotes.filter(u => u.trackId === t2.id).length
      const byLikes = () => {
        if (t1Likes < t2Likes) return 1
        if (t1Likes === t2Likes) {
          const t1index = tracks.findIndex(v => v.id === t1.id)
          const t2index = tracks.findIndex(v => v.id === t2.id)
          if (t1index < t2index) return -1
          if (t1index > t2index) return 1
          return 0
        }
        return -1
      }
      if (t1Paid && t2Paid) {
        return byLikes()
      } else if (t1Paid) {
        return -1
      } else if (t2Paid) {
        return 1
      } else {
        return byLikes()
      }
    },
    [paidTracks, upVotes, tracks]
  )
  return (
    <>
      {isOwnerWithPlaylist && room && (
        <Player
          key="player"
          tracks={tracks}
          buffers={buffers}
          ref={player}
          setPlaylist={setPlaylist}
          sort={compareFn}
          onPlaying={id => {
            if (typeof id === 'number')
              return services.updatePlaying(room.id, id)
          }}
          onRemoved={id => services.tracks.remove(room.id, id)}
          render={
            playingTrack && (
              <Flex justifyContent="space-between" align="center" w="full">
                <HStack spacing="4" overflow="hidden">
                  <Box pos="relative" boxSize="80px" style={{ flexShrink: 0 }}>
                    <Image
                      src={playingTrack.thumbnailUrl!}
                      layout="fill"
                      alt={`${playingTrack.title} cover`}
                      className="rounded-md"
                    />
                  </Box>

                  <Stack minW="0">
                    <HStack>
                      <Text
                        fontWeight="bold"
                        textAlign="left"
                        whiteSpace="nowrap"
                        overflow="hidden"
                        textOverflow="ellipsis"
                        textColor="green.300"
                      >
                        {playingTrack.title}
                      </Text>
                    </HStack>

                    <Text
                      textAlign="left"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      textColor="green.300"
                    >
                      {playingTrack.artist}
                    </Text>
                  </Stack>
                </HStack>
              </Flex>
            )
          }
        />
      )}
      {!isSearching && <AddMusicButton room={room} online={isPlaylistOnline} />}
      <Stack alignItems="center">
        <Stack direction="column" pt="4" maxWidth={500} width="full">
          {isSearching ? (
            <Search
              tracks={tracks}
              room={room}
              user={user}
              upVotes={upVotes}
              paidTracks={paidTracks}
            />
          ) : (
            <Playlist
              onClick={async id => {
                if (isOwner) {
                  await player.current?.play(id)
                }
              }}
              tracks={
                playlist.length > 0
                  ? playlist.flatMap(t => {
                      const val = tracks.find(track => track.id === t.id)
                      return val ? [val] : []
                    })
                  : services.tracks.sort(
                      tracks,
                      upVotes,
                      paidTracks,
                      room?.playing
                    )
              }
              room={room}
              user={user}
              upVotes={upVotes}
              paidTracks={paidTracks}
              isOnline={isPlaylistOnline}
            />
          )}
        </Stack>
        <Box height="150" />
      </Stack>
    </>
  )
}

export default dynamic(async () => RoomPage, { ssr: false })
