import React from 'react'
import { isRoomOwner } from '@/services/user'
import { PaidTrack, Room, UpVote, Track } from '@/types/room'
import { User } from '@/types/user'
import { Stack, Text, useToast } from '@chakra-ui/react'
import { useDispatch } from '@/store/hooks'
import * as actions from '@/store/actions'
import { MusicItem } from './MusicItem'
import { useTranslation } from 'react-i18next'
import * as analytics from '@/services/analytics'
import strings from '@/strings'

export type PlaylistProps = {
  room: Room | null
  upVotes: UpVote[]
  paidTracks: PaidTrack[]
  user: User | null
  isOnline: boolean
  tracks: Track[]
  onClick?: (id: number) => void
}
export const Playlist = (props: PlaylistProps) => {
  const { room, upVotes, paidTracks, user, isOnline, tracks } = props
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const toast = useToast({ status: 'error', variant: 'solid' })
  const roomId = room?.id
  const ownerId = user?.id
  const onVote = async (upOrDown: 'up' | 'down', trackId: number) => {
    if (!roomId || !ownerId) return
    const action = upOrDown === 'up' ? 'upvote_music' : 'downvote_music'
    analytics.add({ action, category: 'engagement' })
    const params = { ownerId, trackId, roomId }
    const result = await dispatch(actions.music.onVote(upOrDown, params))
    if (!result) {
      const title = t(strings.playlist.voteFailed)
      const description = t(strings.playlist.networkError)
      toast({ title, description })
    }
  }
  return (
    <Stack px={4} maxW="500px" spacing="4" w="full" pb={20}>
      {isOnline &&
        room &&
        tracks.map((music, idx) => {
          const upVotes_ = upVotes
            .filter(u => u.trackId === music.id)
            .map(u => u.ownerId)
          return (
            <MusicItem
              key={music.id}
              {...music}
              onClick={() => (props.onClick ?? (_id => {}))(music.id)}
              isPlaying={idx === 0}
              upVotes={upVotes_}
              isPaid={paidTracks.findIndex(t => t.trackId === music.id) !== -1}
              userId={user?.id}
              roomOwnerId={room.ownerId}
              canSkip={isRoomOwner(user?.id, room.ownerId) && idx === 0}
              onUpVote={() => onVote('up', music.id)}
              onDownVote={() => onVote('down', music.id)}
              onSkip={() => dispatch(actions.music.removeTrack(tracks[0]))}
              onRemove={() => dispatch(actions.music.removeTrack(music))}
            />
          )
        })}
      {!isOnline && <Text>{t(strings.playlist.noJukebox)}</Text>}
    </Stack>
  )
}
