import * as services from '@/services'
import { sleep } from './thread'

const logger = (...args: any) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(...args)
  }
}

export type ID = string | number
export type Track = {
  id: ID
  title: string
  serviceId: string
}

type SourceDetails = {
  id: ID
  buffer: Blob
}

export type Type = 'playing' | 'paused' | 'sorted' | 'ended' | 'removed'
export type CompareFn = (track1: Track, track2: Track) => number
export type Constructor = {
  tracks: Track[]
  sort?: CompareFn
  context: HTMLAudioElement
}
export class DixHeures {
  #reads: Set<ID>
  #tracks: Track[]
  #playing?: ID
  #closed: boolean
  #context: HTMLAudioElement
  #sourceDetails?: SourceDetails
  #nextSourceDetails?: SourceDetails
  #eventListeners: {
    ended: ((id: ID) => void)[]
    playing: ((id: ID) => void)[]
    paused: ((id: ID) => void)[]
    removed: ((id: ID) => void)[]
    sorted: ((tracks: Track[]) => void)[]
  }
  #sort?: CompareFn
  #paused: boolean
  #downloading: boolean
  #queue: ID[]

  constructor({ tracks, context, sort }: Constructor) {
    this.#tracks = [...tracks]
    this.#context = context
    this.#closed = false
    this.#sort = sort
    this.#paused = false
    this.#queue = []
    this.#downloading = false
    this.#reads = new Set()
    this.#eventListeners = {
      ended: [],
      playing: [],
      paused: [],
      removed: [],
      sorted: [],
    }
    this.updateSortTracks()
    this.#context.addEventListener('ended', event => this.#onEndSong(event))
    if (this.#tracks.length > 0) this.#readSource(this.#tracks[0].id, true)
    this.#tracks.forEach(t => this.#enqueue(t.id))
    this.#sortEnqueue()
    logger('Player initiated')
  }

  async #selectBufferSource(id: ID, track: Track) {
    if (this.#sourceDetails?.id === id) {
      return this.#sourceDetails.buffer
    } else if (this.#nextSourceDetails?.id === id) {
      return this.#nextSourceDetails.buffer
    } else {
      const buffer = await services.musics.now(track.serviceId)
      if (buffer) return new Blob([buffer], { type: 'audio/aac' })
    }
  }

  #enqueue(id: ID) {
    this.#queue.push(id)
    if (!this.#downloading) {
      this.#downloadNextSong()
    }
  }

  #sortEnqueue() {
    this.#queue.sort((a, b) => {
      const idx1 = this.#tracks.findIndex(t => t.id === a)
      const idx2 = this.#tracks.findIndex(t => t.id === b)
      if (idx1 < idx2) return -1
      if (idx1 > idx2) return 1
      return 0
    })
  }

  async #downloadNextSong(bypass?: boolean): Promise<void> {
    if (this.#downloading && !bypass) return
    logger('Downloading next song', this.#queue[0])
    this.#downloading = true
    const first = this.#queue.shift()
    if (first) {
      await this.#readSource(first, true)
      await sleep(1000)
      this.#downloadNextSong(true)
    } else {
      this.#downloading = false
    }
  }

  async #readSource(id: ID, forecast?: boolean) {
    logger('Read source, forecast', forecast ?? false)
    const track = this.#tracks.find(track => track.id === id)
    if (!track) return null
    const buffer = await this.#selectBufferSource(id, track)
    if (!buffer) return
    if (forecast) {
      this.#nextSourceDetails = { id, buffer }
    } else {
      this.#sourceDetails = { id, buffer }
      return buffer
    }
  }

  async #onEndSong(_event: Event) {
    logger('OnEndSong')
    const listeners = this.#eventListeners.ended
    listeners.forEach(listener => listener(this.#playing!))
    if (!this.#paused) await this.next()
  }

  addListener(type: 'ended', callback: (id: ID) => void): void
  addListener(type: 'playing', callback: (id: ID) => void): void
  addListener(type: 'paused', callback: (id: ID) => void): void
  addListener(type: 'removed', callback: (id: ID) => void): void
  addListener(type: 'sorted', callback: (tracks: Track[]) => void): void
  addListener(type: Type, callback: any) {
    this.#eventListeners[type].push(callback)
  }

  removeListener(type: 'ended', callback: (id: ID) => void): void
  removeListener(type: 'playing', callback: (id: ID) => void): void
  removeListener(type: 'paused', callback: (id: ID) => void): void
  removeListener(type: 'removed', callback: (id: ID) => void): void
  removeListener(type: 'sorted', callback: (tracks: Track[]) => void): void
  removeListener(type: Type, callback: any) {
    const listeners: any[] = this.#eventListeners[type]
    this.#eventListeners[type] = listeners.filter(listener => {
      return listener !== callback
    })
  }

  async add(track: Track) {
    const exists =
      this.#tracks.find(t => t.id === track.id) || this.#reads.has(track.id)
    if (exists) return false
    const previousLength = this.#tracks.length
    this.#tracks.push(track)
    await this.updateSortTracks()
    if (previousLength === 0) {
      await this.#readSource(track.id, true)
    } else {
      this.#enqueue(track.id)
    }
    return true
  }

  async addAll(tracks: Track[]) {
    const newTracks = tracks
      .filter(t => !this.#reads.has(t.id))
      .filter(t => !this.#tracks.find(t_ => t.id === t_.id))
    const previousLength = this.#tracks.length
    this.#tracks = this.#tracks.concat(newTracks)
    await this.updateSortTracks()
    if (previousLength === 0 && this.#tracks.length > 0)
      await this.#readSource(this.#tracks[0].id)
    newTracks.forEach(track => this.#enqueue(track.id))
    return true
  }

  async replace(tracks: Track[]) {
    logger('Replace')
    const correctTracks = tracks.filter(t => !this.#reads.has(t.id))
    const tracks_ = correctTracks.filter(
      t => !this.#tracks.find(t_ => t.id === t_.id)
    )
    this.#tracks = correctTracks
    await this.updateSortTracks()
    await this.#readSource(this.#tracks[0].id, true)
    tracks_.forEach(track => this.#enqueue(track.id))
    return true
  }

  async remove(id: ID) {
    logger('Remove, id:', id)
    if (this.#closed) return
    this.#reads.add(id)
    if (this.#playing === id) this.stop()
    this.#tracks = this.#tracks.filter(track => track.id !== id)
    this.#eventListeners.removed.forEach(listener => listener(id))
    await this.updateSortTracks()
  }

  async play(id?: ID) {
    logger('Play, id:', id)
    if (this.#closed) return false
    if (id && this.#playing === id) return false
    if (!id && this.#playing) return false
    const finalID = id ?? this.#tracks[0]?.id
    const source = await this.#readSource(finalID)
    if (!source) return false
    if (this.#playing) this.remove(this.#playing)
    this.#paused = false
    this.#context.src = window.URL.createObjectURL(source)
    this.#context.play()
    this.#playing = finalID
    this.#eventListeners.playing.forEach(listener => listener(finalID))
    this.updateSortTracks()
    return true
  }

  async resume() {
    logger('Resume')
    if (this.#closed) return false
    if (this.#playing) return false
    if (!this.#sourceDetails) return await this.play()
    this.#paused = false
    this.#context.play()
    this.#playing = this.#sourceDetails.id
    this.#eventListeners.playing.forEach(listener => listener(this.#playing!))
    return true
  }

  pause() {
    logger('Pause')
    if (this.#closed) return false
    if (!this.#playing) return false
    this.#paused = true
    this.#context.pause()
    this.#eventListeners.paused.forEach(listener => listener(this.#playing!))
    this.#playing = undefined
    return true
  }

  stop() {
    logger('Stop')
    this.pause()
    this.#context.src = ''
  }

  async #findNextId() {
    if (this.#tracks.length > 1) {
      for (const track of this.#tracks.slice(1)) {
        const exists = await services.musics.exists(track.serviceId)
        if (exists) return track.id
      }
      return this.#tracks[1].id
    }
    return null
  }

  async next() {
    logger('Next')
    if (this.#closed) return false
    if (!this.#playing) return this.play()
    const nextPlaying = await this.#findNextId()
    if (nextPlaying) return this.play(nextPlaying)
    await this.remove(this.#playing)
    return this.stop()
  }

  async close() {
    logger('Close')
    this.#closed = true
    this.#context.pause()
  }

  async updateSortTracks(compareFn?: CompareFn) {
    this.#sort = compareFn ?? this.#sort
    this.#tracks = [...this.#tracks].sort((a, b) => {
      if (a.id === this.#playing) return -1
      if (b.id === this.#playing) return 1
      if (this.#sort) return this.#sort(a, b)
      return 0
    })
    this.#eventListeners.sorted.forEach(listener => listener(this.#tracks))
    this.#sortEnqueue()
  }

  get playing() {
    return this.#playing
  }

  get tracks() {
    return this.#tracks
  }
}
