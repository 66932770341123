import { VolumeHighIcon } from '@/assets/icons/VolumeHighIcon'
import { Trash } from '@/assets/icons/Trash'
import { Track } from '@/types/room'
import { ArrowUpIcon } from '@chakra-ui/icons'
import {
  Flex,
  HStack,
  Box,
  Stack,
  Button,
  Text,
  Tag,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  useDisclosure,
} from '@chakra-ui/react'
import Image from 'next/image'
import { SkipIcon } from '@/assets/icons/SkipIcon'
import { isRoomOwner } from '@/services/user'
import { useTranslation } from 'react-i18next'
import strings from '@/strings'

export const MusicItem = ({
  userId,
  isPlaying,
  upVotes,
  isPaid,
  onUpVote,
  onDownVote,
  roomOwnerId,
  canSkip,
  onSkip,
  onRemove,
  onClick,
  ...music
}: Track & {
  roomOwnerId: string
  canSkip?: boolean
  userId?: string
  isPlaying: boolean
  upVotes: string[]
  isPaid: boolean
  onUpVote: () => void
  onDownVote: () => void
  onSkip: () => void
  onRemove: () => void
  onClick: () => void
}) => {
  const { t } = useTranslation()

  const onVote = () => {
    if (!userId) return
    const existingVote = upVotes.indexOf(userId)
    existingVote === -1 ? onUpVote() : onDownVote()
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  const onDelete = (type: 'open' | 'close') => {
    if (isRoomOwner(userId, roomOwnerId)) {
      if (type === 'open') onOpen()
      if (type === 'close') {
        onRemove()
        onClose()
      }
    }
  }

  return (
    <>
      <Modal colorScheme="purple" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t(strings.playlist.deleteMusic)}</ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <Button colorScheme="purple" mr={3} onClick={onClose}>
              {t(strings.common.no)}
            </Button>
            <Button onClick={() => onDelete('close')}>
              {t(strings.common.yes)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Flex
        justifyContent="space-between"
        align="center"
        w="full"
        data-testid="music-item"
      >
        <HStack spacing="4" overflow="hidden" mr="4" onClick={onClick}>
          <Box pos="relative" boxSize="80px" style={{ flexShrink: 0 }}>
            <Image
              src={music.thumbnailUrl!}
              layout="fill"
              alt={`${music.title} cover`}
              className="rounded-md"
            />
            {isPlaying && (
              <Flex
                justify="center"
                align="center"
                pos="absolute"
                boxSize="full"
                h="full"
                bgColor="rgba(0,0,0,0.5)"
                rounded="md"
              >
                <VolumeHighIcon boxSize="30px" />
              </Flex>
            )}
          </Box>

          <Stack minW="0">
            <HStack>
              <Text
                fontWeight="bold"
                textAlign="left"
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                textColor={isPlaying ? 'green.300' : 'white'}
              >
                {music.title}
              </Text>
              {isPaid && (
                <Tag
                  colorScheme="orange"
                  style={{ flexShrink: 0 }}
                  variant="solid"
                >
                  {t(strings.common.paid)}
                </Tag>
              )}
            </HStack>

            <Text
              textAlign="left"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
              textColor={isPlaying ? 'green.300' : 'gray.300'}
            >
              {music.artist}
            </Text>
          </Stack>
        </HStack>
        <HStack>
          {isRoomOwner(userId, roomOwnerId) && (
            <Button
              onClick={() => onDelete('open')}
              size="sm"
              style={{ flexShrink: 0 }}
            >
              <Trash boxSize="20px" color="white" mr="1" />
            </Button>
          )}
          {!isPlaying && (
            <Button onClick={onVote} size="sm" style={{ flexShrink: 0 }}>
              <ArrowUpIcon
                boxSize="20px"
                color={upVotes.includes(userId ?? '') ? 'orange.400' : 'white'}
                mr="1"
              />
              {upVotes.length}
            </Button>
          )}
        </HStack>
      </Flex>
    </>
  )
}
