import React, { FormEvent, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useDebounce } from 'use-debounce'
import { SearchIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { getGoogleSuggestions } from '@/services/search'
import { useTranslation } from 'react-i18next'
import * as analytics from '@/services/analytics'
import strings from '@/strings'

export type SearchInputProps = {
  isOwner: boolean
  onSubmit: (inputValue: string, type: 'music' | 'playlist') => void
}
export const SearchInput = ({ isOwner, onSubmit }: SearchInputProps) => {
  const { t } = useTranslation()
  const [searchValue, setSearch] = useState<string>('')
  const [typeValue, setTypeValue] = useState<'music' | 'playlist'>('music')
  const [searchValueDebounced] = useDebounce(searchValue, 300)
  const [autocompleteSuggestions, setAutocompleteSuggestions] = useState<
    string[]
  >([])
  const inputRef = useRef<HTMLInputElement | null>(null)
  const formRef = useRef<HTMLFormElement | null>(null)
  const [focusingSuggestionsIndex, setFocusingSuggestionsIndex] = useState<
    number | undefined
  >()
  const [showAutoCompleteList, setShowAutoCompleteList] = useState(false)

  useOutsideClick({
    ref: formRef,
    handler: () => {
      setShowAutoCompleteList(false)
    },
  })

  useEffect(() => {
    const run = async () => {
      const enoughLength = searchValueDebounced?.length >= 3
      const isActiveElement = () => document.activeElement === inputRef.current
      if (enoughLength && isActiveElement()) {
        analytics.search(searchValueDebounced)
        const allSuggestions = await getGoogleSuggestions(searchValueDebounced)
        const suggestions = allSuggestions.slice(0, 8)
        if (isActiveElement()) setAutocompleteSuggestions([...suggestions])
      } else {
        setAutocompleteSuggestions([])
        setFocusingSuggestionsIndex(undefined)
      }
    }
    run()
  }, [searchValueDebounced])

  const handleInputChange = async (event: any) => {
    setSearch(event.target.value)
    if (event.target.value === '') {
      setAutocompleteSuggestions([])
      setFocusingSuggestionsIndex(undefined)
      setSearch('')
    }
  }

  const searchSubmitHandler = (e: FormEvent) => {
    e.preventDefault()
    setAutocompleteSuggestions([])
    setFocusingSuggestionsIndex(undefined)
    onSubmit(searchValue, typeValue)
  }

  const clearInputHandler = () => {
    setAutocompleteSuggestions([])
    setFocusingSuggestionsIndex(undefined)
    setSearch('')
  }

  const keypressHandler = (event: any) => {
    if (event.key === 'Enter') {
      setAutocompleteSuggestions([])
      setFocusingSuggestionsIndex(undefined)
      // @ts-ignore
      inputRef.current!.blur()
      // @ts-ignore
      inputRef.current!.value = ''
    }
    if (event.key === 'ArrowDown') {
      if (focusingSuggestionsIndex === undefined) {
        setFocusingSuggestionsIndex(0)
        setSearch(autocompleteSuggestions[0])
        return
      }
      if (focusingSuggestionsIndex === autocompleteSuggestions.length - 1)
        return
      setFocusingSuggestionsIndex(focusingSuggestionsIndex + 1)
      setSearch(autocompleteSuggestions[focusingSuggestionsIndex + 1])
    } else if (event.key === 'ArrowUp') {
      if (focusingSuggestionsIndex === undefined) return
      if (focusingSuggestionsIndex === 0) {
        setFocusingSuggestionsIndex(undefined)
        return
      }
      setFocusingSuggestionsIndex(focusingSuggestionsIndex - 1)
      setSearch(autocompleteSuggestions[focusingSuggestionsIndex - 1])
    }
  }

  const handleInputFocus = () => {
    setShowAutoCompleteList(true)
  }

  return (
    <Stack>
      <Box pos="relative" mb="2">
        <form onSubmit={searchSubmitHandler} autoComplete="off" ref={formRef}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <SearchIcon />
            </InputLeftElement>
            <Input
              _placeholder={{ color: 'gray.100' }}
              onFocus={handleInputFocus}
              ref={inputRef}
              borderRadius="full"
              variant="filled"
              type="text"
              id="search"
              placeholder={t(strings.search.placeholder)}
              value={searchValue}
              onKeyUp={keypressHandler}
              onChange={handleInputChange}
              autoCorrect="off"
              autoComplete="off"
            />
            {searchValue !== '' && (
              <InputRightElement width="3rem">
                <IconButton
                  variant="ghost"
                  aria-label="clear"
                  h="1.75rem"
                  size="sm"
                  onClick={clearInputHandler}
                  icon={<SmallCloseIcon />}
                />
              </InputRightElement>
            )}
          </InputGroup>
          {showAutoCompleteList && (
            <VStack
              spacing={1}
              pos="absolute"
              bgColor="gray.900"
              w="full"
              rounded="lg"
              zIndex="1"
            >
              {autocompleteSuggestions.map((suggestion, idx) => (
                <Flex
                  key={idx}
                  w="full"
                  p={2}
                  bgColor={
                    idx === focusingSuggestionsIndex
                      ? 'gray.700'
                      : 'transparent'
                  }
                  rounded="lg"
                  onClick={() => {
                    setSearch(suggestion)
                    setAutocompleteSuggestions([])
                    setFocusingSuggestionsIndex(undefined)
                    onSubmit(suggestion, typeValue)
                  }}
                  cursor="pointer"
                  _hover={{ bgColor: 'gray.700' }}
                >
                  {suggestion}
                </Flex>
              ))}
            </VStack>
          )}
        </form>
      </Box>
      {isOwner && (
        <HStack>
          <Button
            rounded="full"
            colorScheme={'orange'}
            variant={typeValue === 'music' ? 'solid' : 'outline'}
            onClick={() => {
              setTypeValue('music')
              onSubmit(searchValue, 'music')
            }}
            size="sm"
          >
            Musics
          </Button>
          <Button
            rounded="full"
            colorScheme="orange"
            variant={typeValue === 'playlist' ? 'solid' : 'outline'}
            onClick={() => {
              setTypeValue('playlist')
              onSubmit(searchValue, 'playlist')
            }}
            size="sm"
          >
            Playlists
          </Button>
        </HStack>
      )}
    </Stack>
  )
}
